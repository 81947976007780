















































































































































































































import { Component, Vue } from "vue-property-decorator";
import { saveAs } from "file-saver";
import {
  communityActivitiesReportOptions,
  caseManagementReportOptions,
  communicationReportOptions,
  extractionReportOptions,
  legalServicesReportOptions,
  prescreeningReportOptions,
  socialIntegrationReportOptions
} from "../components/reports/reportsSelectOptions";

@Component({
  components: {}
})
export default class Reports extends Vue {
  private from!: string;
  private to!: string;
  private prescreening = prescreeningReportOptions;
  private caseManagement = caseManagementReportOptions;
  private communication = communicationReportOptions;
  private extraction = extractionReportOptions;
  private community = communityActivitiesReportOptions;
  private legalServices = legalServicesReportOptions;
  private socialIntegration = socialIntegrationReportOptions;

  mounted() {
    const currentDate = new Date();
    this.to = currentDate.toISOString().split("T")[0];
    currentDate.setMonth(currentDate.getMonth() - 3);
    this.from = currentDate.toISOString().split("T")[0];
  }

  async download(reportType: string, reportsToDownload: any, title: string) {
    const reportFilters = {
      ReportsToDownload: reportsToDownload.map((x: any) => x.value),
      ReportType: reportType,
      StartDate: this.from,
      EndDate: this.to,
      Download: true
    };
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(await this.$service.downloadReports(reportFilters),
    `${title}_Report_${utc}.xlsx`
    );
  }
}
