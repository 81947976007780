var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-pa-md"},[_c('div',{staticClass:"row q-col-gutter-md"},[(_vm.$auth.permissions.admin.is || _vm.$auth.permissions.reports.caseManagement)?_c('div',{staticClass:"col-12 col-sm-6 col-md-4 col-lg-3 flex"},[_c('q-card',{staticClass:"bg-secondary text-white fit flex-card",attrs:{"bordered":""}},[_c('q-card-section',{staticClass:"q-pt-xs"},[_c('div',{staticClass:"text-overline"},[_vm._v("Predefined")]),_c('div',{staticClass:"text-h5 q-mt-sm q-mb-xs"},[_vm._v("Management")]),_c('div',{staticClass:"text-caption text-grey-2"},[_vm._v("Case workers information")])]),_c('q-separator',{attrs:{"dark":""}}),_c('q-card-actions',[_c('q-btn',{attrs:{"flat":""},on:{"click":function($event){_vm.$runAsync('downloading', function () { return _vm.download('CaseManagementReport', _vm.caseManagement, 'Case_Management'); })}}},[_vm._v("Last Period")]),_c('q-btn',{attrs:{"flat":""},on:{"click":function($event){return _vm.$router.push({
                name: 'CaseManagementReport',
                params: { reportType: 'CaseManagementReport', title: 'Case Management' }
              })}}},[_vm._v("Select Time")])],1)],1)],1):_vm._e(),(_vm.$auth.permissions.admin.is || _vm.$auth.permissions.reports.socialIntegration)?_c('div',{staticClass:"col-12 col-sm-6 col-md-4 col-lg-3 flex"},[_c('q-card',{staticClass:"bg-accent text-white fit flex-card",attrs:{"bordered":""}},[_c('q-card-section',{staticClass:"q-pt-xs"},[_c('div',{staticClass:"text-overline"},[_vm._v("Predefined")]),_c('div',{staticClass:"text-h5 q-mt-sm q-mb-xs"},[_vm._v("Psychosocial Services")]),_c('div',{staticClass:"text-caption text-grey-2"},[_vm._v(" Ensuring integration of recognized refugees & asylum seekers – developing a creative plan for an autonomous life ")])]),_c('q-separator',{attrs:{"dark":""}}),_c('q-card-actions',[_c('q-btn',{attrs:{"flat":""},on:{"click":function($event){_vm.$runAsync('downloading', function () { return _vm.download('SocialIntegrationReport', _vm.socialIntegration, 'Psychosocial_Services'); })}}},[_vm._v("Last Period")]),_c('q-btn',{attrs:{"flat":""},on:{"click":function($event){return _vm.$router.push({
                name: 'SocialIntegrationReport',
                params: { reportType: 'SocialIntegrationReport', title: 'Psychosocial Services' }
              })}}},[_vm._v("Select Time")])],1)],1)],1):_vm._e(),(_vm.$auth.permissions.admin.is || _vm.$auth.permissions.reports.extraction)?_c('div',{staticClass:"col-12 col-sm-6 col-md-4 col-lg-3 flex"},[_c('q-card',{staticClass:"bg-primary text-white fit flex-card",attrs:{"bordered":""}},[_c('q-card-section',{staticClass:"q-pt-xs"},[_c('div',{staticClass:"text-overline"},[_vm._v("Predefined")]),_c('div',{staticClass:"text-h5 q-mt-sm q-mb-xs"},[_vm._v("Extractions")]),_c('div',{staticClass:"text-caption text-grey-2"},[_vm._v(" Extractions from different areas of the solution ")])]),_c('q-separator',{attrs:{"dark":""}}),_c('q-card-actions',[_c('q-btn',{attrs:{"flat":""},on:{"click":function($event){_vm.$runAsync('downloading', function () { return _vm.download('ExtractionReport', _vm.extraction); })}}},[_vm._v("Last Period")]),_c('q-btn',{attrs:{"flat":""},on:{"click":function($event){return _vm.$router.push({
                name: 'ExtractionReport',
                params: { reportType: 'ExtractionReport', title: 'Extraction' }
              })}}},[_vm._v("Select Time")])],1)],1)],1):_vm._e(),_c('q-separator',{staticStyle:{"visibility":"hidden"},attrs:{"spaced":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }